const setters = {
  SET_SEMENCES(state, data) {
    state.semences = data;
  },
  SET_SUMMARY(state,data){
    state.summary=data;
  }
};

export default setters;
