const setters = {
  SET_PROD(state, data) {
    state.produitsPhyto = data;
  },
  SET_EXPORTATIONS(state,data){
    state.exportations=data
  },
  SET_IMPORTATIONS(state,data){
    state.importations=data
  },
  SET_SUMMARY(state,data){
    state.summary=data
  }
};

export default setters;
