const states = {
  msg: "Hello world",
};

const setters = {};

const getters = {};

const actions = {};

const session = {
  state: states,
  mutations: setters,
  getters: getters,
  actions: actions,
};

export default session;
