const states = {
    count: 0,
    agents: [],
    ipas: [],
    itas: [],
    selectedIpa: null,
    itasOfIpa: [],
    formulaires: [] /*Liste des formulaires */ ,
    categories: [] /*Liste des categories cultures */ ,
    dataLoading: false,
    formulaireSection: null /*Selection une section de formulaire*/ ,
    dashboard: {},
    access:{}
};

export default states;