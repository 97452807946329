import Api from "@/apps/dprotv/api";

const actions = {
  /**Permet de voir les semences via le store
   * @callback
   */
  viewProduitsPhyto({ commit }) {
    Api.voirProduitsPhyto((data) => {
      if (data !== null) {
        if (data.produit_phytosanitaires !== undefined) {
          commit("SET_PROD", data.produit_phytosanitaires.reverse());
        }
      }
    });
  },

  viewExportations({commit})
  {
    Api.viewExportations((res)=>{
      var exportations=res.data.exportations;
      commit("SET_EXPORTATIONS",exportations);
    });
  },
  viewSummary({commit})
  {
    Api.viewSummary((res)=>{
      var summary=res.data.summary;
      commit("SET_SUMMARY",summary);
    });
  },

  viewImportations({commit})
  {
    Api.viewImportations((res)=>{
      var importations=res.data.importations;
      commit("SET_IMPORTATIONS",importations);
    });
  }

};

export default actions;
