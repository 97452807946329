import { request } from "@/http";

class Api {
  /**
   * Creation d'un nouveau fertilisant
   * @function
   * @param {object} form
   * @return promise
   */
  static async creerProduitPhyto(form) {
    const { data, status } = await request(
      form,
      "/dprotv/produits/add"
    );
    return new Promise((resolve, reject) => {
      if (status == 200 && data.reponse.status === "success") {
        resolve(true);
      } else reject(false);
    });
  }

  /**
   * Affichage de la liste des semences certifiées enregistrées
   * @callback data
   */
  static async voirProduitsPhyto(callback) {
    const { data, status } = await request(null, "/dprotv/produits/view");
    if (status === 200) callback(data);
    else callback(null);
  }

  static async viewSummary(callback) {
    var res =await request(null, "/dprotv/summary");

    callback(res);
  }


  static async saveExportation(data)
  {
    await request(data,"/dprotv/exportations/add");
  }

  static async saveImportation(data)
  {
    await request(data,"/dprotv/importations/add");
  }

  static async viewExportations(callback)
  {
    var res =await request(null,"/dprotv/exportations/view");

    callback(res);
  }

  static async viewImportations(callback)
  {
    var res =await request(null,"/dprotv/importations/view");

    callback(res);
  }
}

export default Api;
