<template>
    <footer class="footer">
        <div class="container">
            <nav class="pull-left">
                <ul class="nav">
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" href="javascript:void(0)">
                            Aide ?
                        </a>
                    </li>
                </ul>
            </nav>
            <div class="copyright ml-auto">
                Snia RDC. MinAgri RDC.
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "Global-Footer"
}
</script>