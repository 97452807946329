import { request } from "@/http";

class Api {
  /**
   * Login
   * @param {Object} form
   * @param {void} callback
   */
  static async login(form, callback) {
    const user = {
      key: "cc0523cbb903908ddd19590e1e99dacf03ae41ae",
      identifiant: form.identifiant,
      pass: form.pass,
    };
    const { data, status } = await request(user, "/connexion/connexion_url");
    if (status === 200) callback(data);
    else callback(false);
  }
  /**
   * Création d'une Ipa
   * @param {Object} form
   * @param {void} callback
   */
  static async creerIpa(form, callback) {
    const { data, status } = await request(
      {
        key: "2f53b23743d1f836a4da181578aa4b518f0a7294",
        code_ipa: form.code_ipa,
        province: form.province,
        superficie: form.superficie,
        longitude: form.longitude,
        latitude: form.latitude,
      },
      "/dantic/ipa_url/safeguardIpa"
    );

    console.clear();
    console.log(data);

    if (status === 200) callback(data);
    else callback(false);
  }
  /**
   * voir les ipas
   * @param {void} callback
   */
  static async voirIpas(callback) {
    const { data, status } = await request(
      {
        key: "01b5cbf370c7cb6029604d06b8fdc80c90ea8ac1",
      },
      "/dantic/ipa_url/viewIpa"
    );
    if (status === 200) callback(data);
    else callback(false);
  }

  /**
   * Création d'une Ita
   * @param {Object} form
   * @param {void} callback
   */
  static async creerIta(form, callback) {
    const { data, status } = await request(
      {
        key: "41cd043dd54a65c79f59b415b71c0d36374d4678",
        ipa_id: form.ipa_id,
        ita: form.ita,
        province: form.province,
        code_ita: form.code_ita,
        superficie: form.superficie,
        longitude: form.longitude,
        latitude: form.latitude,
      },
      "/dantic/ita_url/safeguardIta"
    );
    if (status === 200) callback(data);
    else callback(false);
  }
  /**
   * Voir les Itas
   * @param {void} callback
   */
  static async voirItas(callback) {
    const { data, status } = await request(
      {
        key: "c93877c55c4ab3d7961672578263d6de728a3984",
      },
      "/dantic/ita_url/viewIta"
    );
    if (status === 200) callback(data);
    else callback(false);
  }

  /**
   * voir les itas relatives  à l'Ipa sélectionnée
   * @param {String} ipaId
   * @param {void} callback
   */
  static async voirItasDeIpa(ipaId, callback) {
    const { status, data } = await request(
      {
        key: "c42806c710889ad890b10fb6357d7fc58b04df05",
        ipa_id: ipaId,
      },
      "/dantic/ipa_url/viewItaById"
    );
    if (status === 200) callback(data);
    else callback(false);
  }

  /**
   * Création
   * @param {Object} form
   * @param {void} callback
   */
  static async creerAgent(form, callback) {
    const { data, status } = await request(
      {
        key: "702b7602260a75535560f1a21d698a9f51a808b9",
        nom_complet: form.nom_complet,
        fonction_publique_status: form.statut,
        fonction: form.fonction,
        grade: form.grade,
        sexe: form.sexe,
        date_naissance: form.date_naissance,
        numero_reference: form.numero_reference,
        email: form.email,
        telephone: form.telephone,
        pass: form.pass,
        access: form.access,
      },
      "/dantic/agent_url/safeguardAgent"
    );
    console.clear();
    console.log(data);
    if (status === 200) callback(data);
    else callback(false);
  }

  /**
   * Voir tous les agents
   * @param {void} callback
   */
  static async voirAgents(callback) {
    const { data, status } = await request(
      {
        key: "9356560e3b9554c51aca9f7c997c4e369455e2a7",
      },
      "/dantic/agent_url/viewAgent"
    );
    let agents = data.reponse;
    if (status === 200) callback(agents);
    else callback(false);
  }

  /**
   * Création d'une catégorie de culture
   * @param {Object} form
   * @param {void} callback
   */
  static async creerCategorie(form, callback) {
    const { data, status } = await request(
      {
        key: "8ccd50b3e98abc0e27bec30c9f8c5ab90570ee1c",
        categorie: form.categorie,
      },
      "/dantic/culture_url/safeguardcategorie"
    );
    console.clear();
    console.log(data);
    if (status === 200) callback(data);
    else callback(false);
  }

  /**
   * Voir toutes categories cultures
   * @param {void} callback
   */
  static async voirCategories(callback) {
    const { data, status } = await request(
      {
        key: "141b6053fe7e3046487d72f92a30437e5ba0d1a4",
      },
      "/dantic/culture_url/viewcategories"
    );
    if (status === 200) callback(data);
    else callback(false);
  }

  /**
   * Création d'une culture
   * @param {Object} form
   * @param {void} callback
   */
  static async creerCulture(form, callback) {
    const { data, status } = await request(
      {
        culture_categorie_id: form.culture_categorie_id,
        nom: form.nom,
        //detail: form.detail,
        //valeur: form.valeur,
      },
      "/dantic/culture_url/safeguardculture"
    );
    if (status === 200) callback(data);
    else callback(false);
  }

  static async deleteCulture(form,callback)
  {
    await request(form,"dantic/culture_url/delete");
    callback();
  }

  static async deleteCultureCategorie(form,callback)
  {
    await request(form,"dantic/culture_url/delete/categorie");
    callback();
  }

  /* VOir les formulaires enregistrés dans le systèmes */
  static async voirFormulaires(callback) {
    const { data, status } = await request(
      {
        key: "44daa070bcc93a83a0af787630620c35f3b4be6f",
      },
      "/dantic/formulaire_url/viewformulaire"
    );
    if (status === 200) callback(data);
    else callback(false);
  }

  /**
   * Création sujet
   * @param {String} sujet
   * @param {void} callback(response)
   */
  static async creerSujet(sujet, callback) {
    const { data, status } = await request(
      {
        key: "d5e8d0821cc11475c8c07786b186f4deb8b4f26c",
        sujet: sujet,
      },
      "/dantic/formulaire_url/safeguardsujet"
    );
    if (status === 200) callback(data);
    else callback(false);
  }

  /**
   * Création formulaire
   * @param {String} titre
   */
  static async creerFormulaire(titre, callback) {
    const { data, status } = await request(
      {
        key: "f038d6de71414e2247f926d7089c794ab5e4bdab",
        titre: titre,
      },
      "/dantic/formulaire_url/safeguardformulaire"
    );

    let res = data.reponse;
    return new Promise((resolve) => {
      if (status === 200 && res.status === "success")
        resolve({ formulaire_id: res.datas });
    });
  }

  /**
   * Lier formulaire au sujet
   * @param {Object} form
   * @param {void} callback(response)
   */
  static async lierFormulaireToSubject(form) {
    const { data, status } = await request(
      {
        key: "92a5dd7af82ba6c03661201730b46457954776c3",
        formulaire_id: form.formulaire_id,
        sujet_id: form.sujet_id,
      },
      "/dantic/formulaire_url/linkformulaire"
    );
    let res = data.reponse;
    return new Promise((resolve) => {
      if (status === 200 && res.status === "success") resolve(data);
    });
  }

  /**
   * Création section formulaire au sujet
   * @param {Object} form
   * @param {void} callback(response)
   */
  static async creerSectionFormulaire(form) {
    const { data, status } = await request(
      {
        key: "7b3f20ae607014e48bdc1e8a02ed5f17ec7de9af",
        formulaire_id: form.formulaire_id,
        section: form.section,
      },
      "/dantic/formulaire_url/safeguardsection"
    );
    let res = data.reponse;
    console.log(JSON.stringify(res));
    return new Promise((resolve) => {
      if (status === 200 && res.status === "success")
        resolve({ formulaire_section_id: res.datas });
    });
  }

  /**
   * Création Formulaire section Details
   * @param {Object} form
   * @param {void} callback(response)
   */
  static async creerFormulaireSectionDetails(form) {
    console.log(JSON.stringify(form));
    let options = [];
    if (form.options !== undefined) {
      form.options.forEach((el) => {
        options.push(el.input_option);
      });
    }
    console.log(options.toString());
    const { data, status } = await request({
      key: "87e4e76f05ddf374ca292f25d6f53115f5f15eb3",
      formulaire_section_id: form.formulaire_section_id,
      input: form.input,
      input_type: form.input_type,
      options: options.toString(),
    },"/dantic/formulaire_url/configformulaire");
    //console.log("Terminate on ", JSON.stringify(data));
    let res = data.reponse;
    //console.log(res);
    return new Promise((resolve) => {
      if (status === 200 && res.status === "success") {
        /**
         * Send options & sousInputs.
         */
        if (form.options !== undefined && form.options.length > 0) {
          Api.saveInputOptions(res.formulaire_input_id, form.options);
        }
        resolve(data);
      }
    });
  }

  /**
   * Enregistrer chaque option.
   * @param formulaireInputId
   * @param options
   * @returns {Promise<void>}
   */
  static async saveInputOptions(formulaireInputId, options) {
    var data = {
      key: "3a912dbce826b33c2a47c1fd42e3c4a06f488156",
      formulaire_input_id: formulaireInputId,
    };
    console.log("####Save sous options", JSON.stringify(options));
    for (var i = 0; i < options.length; i++) {
      data.input_option = options[i].input_option;
      var res = await request(data,"/dantic/formulaire_url/addinputoption");

      var reponse = res.data.reponse;
      console.log(JSON.stringify(reponse));
      if (reponse.status !== undefined && reponse.status === "success") {
        /**
         * Save sous inputs si nécessaire.
         */
        if (options[i].sous_inputs !== undefined) {
          console.log("####Save sous options start");
          await Api.saveSousInputs(
            reponse.formulaire_input_option_id,
            options[i].sous_inputs
          );
        }
      }
    }
  }

  static async saveSousInputs(formulaireInputOptionId, sous_inputs) {
    var data = {
      key: "7f113d48e98e7bba0ecab83fdbd6e3d2ff3b3c3c",
      formulaire_input_option_id: formulaireInputOptionId,
    };
    console.log("####save sous inputs options");
    for (var i = 0; i < sous_inputs.length; i++) {
      data.sous_input = sous_inputs[i].sous_input;
      data.type = sous_inputs[i].type;

      var res = await request(data,"/dantic/formulaire_url/sousinputs");
      //console.log(res);
      var reponse = res.data.reponse;
      console.log(JSON.stringify(reponse));
      if (reponse.status !== undefined && reponse.status === "success") {
        if (
          sous_inputs[i].sous_options !== undefined &&
          sous_inputs[i].sous_options.length > 0
        ) {
          /**
           * Enregistrer les options des sous inputs si nécessaires.
           */
          console.log("####save sous inputs options start");
          await Api.saveSousInputOptions(
            reponse.formulaire_sous_input_id,
            sous_inputs[i].sous_options
          );
        }
      }
    }
  }

  static async deleteIta(data,callback)
  {
    var res=await request(data,"/dantic/ita_url/delete");

    callback(res);
  }

  /**
   * Enregistrer les options d'un sous champ.
   * @param formulaireSousInputId
   * @param options
   * @returns {Promise<void>}
   */
  static async saveSousInputOptions(formulaireSousInputId, sous_options) {
    var data = {
      key: "fb71b7ba4037aba288e227ccfe974a8fc3e5c109",
      formulaire_sous_input_id: formulaireSousInputId,
    };

    for (var i = 0; i < sous_options.length; i++) {
      data.sous_input_option = sous_options[i].sous_input_option;
      var res = await request(data,"/dantic/formulaire_url/sousinputs/option");
      //console.log(data); console.log(res);
      var reponse = res.data.reponse;
    }
  }

  /**
   * Configuration globale du formulaire
   * @param {Object} formulaireData
   * @param {void} callback(response)
   */
  static async configurerFormulaire(formulaireData,formulaireAccess, callback)
  {
    let { formulaire_id } = await this.creerFormulaire(formulaireData.titre);

    if (formulaire_id !== undefined || formulaire_id !== null)
    {
      /**
       * Set formulaire access.
       */
      this.setFormulaireAccess(formulaire_id,formulaireAccess);

      let liaisonReqData = {
        formulaire_id: formulaire_id,
        sujet_id: formulaireData.sujet_id,
      };
      let liaisonRes = await this.lierFormulaireToSubject(liaisonReqData);
      //console.log("liaison", JSON.stringify(liaisonRes));
      /* check formulaire_sujet link success*/
      if (liaisonRes.reponse.status === "success") {
        for (let i = 0; i < formulaireData.sections.length; i++) {
          let forms = formulaireData.sections[i];

          /*section data object*/
          let f = {
            section: forms.section,
            formulaire_id: formulaire_id,
          };
          /* Création des sections */
          let { formulaire_section_id } = await this.creerSectionFormulaire(f);

          if (
            formulaire_section_id !== undefined ||
            formulaire_section_id !== null
          ) {
            for (let j = 0; j < forms.inputs.length; j++) {
              let content = forms.inputs[j];
              content.formulaire_section_id = formulaire_section_id;
              let inputsRes = await this.creerFormulaireSectionDetails(content);
            }
          }
        }
        callback(true);
      }
    }
  }

  /**
   *  Configurer les accès d'un formulaire.
   * @param formulaireId => ID du formulaire.
   * @param access => array contenant les IDs des modules selectionnés.
   */
  static setFormulaireAccess(formulaireId,access)
  {
      for(var i=0; i<access.length; i++)
      {
        var formData=new FormData();
        formData.append("formulaire_id",formulaireId)
        formData.append("access_config_id",access[i])

        request(formData,"/dantic/formulaire_url/access");
      }

  }

  /**
   * Modification section formulaire
   * @param {Object} sectionData
   * @param {resolve, reject} true|false
   */
  static async modifierSectionFormulaire(s)
  {
    /**
     * Update section title.
     */
        // eslint-disable-next-line no-unreachable
    var formData=new FormData();
    formData.append("formulaire_section_id",s.formulaire_section_id);
    formData.append("section",s.section)
    request(formData,"/dantic/formulaire_url/updateformulaire/section")

    for (let i = 0; i < s.inputs.length; i++)
    {
      let input = s.inputs[i];
      /* let opts = []; */
      /* if (input.options !== undefined) {
                                                                                                                                      } */
      if (input.formulaire_input_id === undefined)
      {
        input.formulaire_section_id = s.formulaire_section_id;
        let fieldRes = await this.creerFormulaireSectionDetails(input);
      }
      else
      {
          request({
            formulaire_input_id: input.formulaire_input_id,
            input: input.input,
            input_type: input.input_type
          },"/dantic/formulaire_url/updateformulaire/input");

          /**
           * Update options in case.
           */
          if(input.options!==undefined)
          {
            Api.updateInputOptions(input.options,input.formulaire_input_id);
          }
      }
    }
    return true;
  }

  static updateInputOptions(options,inputId)
  {
    for(var i=0; i<options.length; i++)
    {
      request({
        "formulaire_input_id":inputId,
        "formulaire_input_option_id":options[i].formulaire_input_option_id,
        "input_option":options[i].input_option
      },"/dantic/formulaire_url/updateformulaire/option")

      /**
       * Update sous inputs in case
       */
      if(options[i].sous_inputs!==undefined)
      {
        Api.updateSousInputs(options[i].sous_inputs,options[i].formulaire_input_option_id);
      }
    }
  }

  static updateSousInputs(sousInputs,inputOptionId)
  {
    for(var i=0; i<sousInputs.length; i++)
    {
      request({
        "formulaire_input_option_id":inputOptionId,
        "formulaire_sous_input_id":sousInputs[i].formulaire_sous_input_id,
        "sous_input":sousInputs[i].sous_input,
        "type":sousInputs[i].type
      },"/dantic/formulaire_url/updateformulaire/sousinput")

      /**
       * Update sous input options ,in case.
       */
      if(sousInputs[i].sous_options!==undefined)
      {
        Api.updateSousInputOptions(sousInputs[i].sous_options,sousInputs[i].formulaire_sous_input_id);
      }
    }
  }

  static updateSousInputOptions(options,sousInputId)
  {
    console.log("sousInputId:"+sousInputId);
    for(var i=0; i<options.length; i++)
    {
        request({
            "formulaire_sous_input_id":sousInputId,
            "formulaire_sous_input_option_id":options[i].formulaire_sous_input_option_id,
            "sous_input_option":options[i].sous_input_option
        },"/dantic/formulaire_url/updateformulaire/sousinputoption")
    }
  }

  static async supprimerFormulaire(formulaireId, callback) {
    let { data, status } = await request({
      formulaire_id: formulaireId,
    },"/dantic/formulaire_url/delete");
    let res = data.reponse.status;
    console.log(JSON.stringify(data));
    if (status == 200 && res === "success") callback(true);
    else callback(false);
  }

  static async editFormTitre(data) {
    //data.key = "a306375b18af9454030614b49285fcc3e1361376";
    await request(data,"/dantic/formulaire_url/updatetitle");
  }

  static async refreshDashboard(state) {
    let danticDashboard = {
      itasCount: state.itas.length,
      ipasCount: state.ipas.length,
      agentsCount: state.agents.length,
    };
    return new Promise((resolve, reject) => {
      resolve(danticDashboard);
    });
  }

  static async getAccess(callback)
  {
    var res=await request(null,"/dantic/access");
    //console.clear(); console.log("access..."); console.log(res);
    callback(res.data.access);
  }
}

export default Api;
